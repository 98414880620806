export function acpMeToMeTransfer($stateProvider: any) {
  'ngInject';
  $stateProvider
    .state('me-to-me-transfer', {
      redirectTo: '.security',
      parent: 'root',
      url: '/me-to-me-transfer',
      templateUrl:
        'sections/me-to-me-transfer/components/me-to-me-transfer-landing/templates/acp-me-to-me-transfer.ng.html',
      data: {
        permissions: {
          only: 'hasMeToMeTransferEnabled',
          redirectTo: {
            hasMeToMeTransferEnabled: 'dashboard'
          }
        }
      },
      resolve: {
        module($ocLazyLoad) {
          'ngInject';
          return import(/* webpackChunkName: "acp.section.me-to-me-transfer" */ './index').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('me-to-me-transfer.landing', {
      template: `<acp-me-to-me-transfer-landing></acp-me-to-me-transfer-landing>`
    })
    .state('me-to-me-transfer.security', {
      template: `<acp-me-to-me-transfer-cvc-check layout="column"></acp-me-to-me-transfer-cvc-check>`,
      data: {
        permissions: {
          except: 'isCVCAuthenticated',
          redirectTo: {
            isCVCAuthenticated: 'me-to-me-transfer.redirect-to-next'
          }
        }
      }
    })
    .state('me-to-me-transfer.esign', {
      template: `<acp-me-to-me-transfer-esign layout-align="center"></acp-me-to-me-transfer-esign>`,
      data: {
        permissions: {
          except: 'isElectronicCommunication',
          redirectTo: {
            isElectronicCommunication: 'me-to-me-transfer.alerts'
          }
        }
      }
    })
    .state('me-to-me-transfer.alerts', {
      template: `<acp-me-to-me-transfer-alerts></acp-me-to-me-transfer-alerts>`,
      data: {
        permissions: {
          except: 'isAlertsEnrolled',
          redirectTo: {
            isAlertsEnrolled: 'me-to-me-transfer.terms'
          }
        }
      }
    })
    .state('me-to-me-transfer.terms', {
      template: `<acp-me-to-me-transfer-terms></acp-me-to-me-transfer-terms>`,
      data: {
        permissions: {
          except: 'isTransferNowTermsAndConditions',
          redirectTo: {
            isTransferNowTermsAndConditions: 'me-to-me-transfer.landing'
          }
        }
      }
    })
    .state('me-to-me-transfer.redirect-to-next', {
      template: `<acp-me-to-me-redirect-to-next></acp-me-to-me-redirect-to-next>`
    });
}
